import type {
  RawDashboardStoreAuthorization,
  DashboardStoreAuthorization,
} from "@zippin-js/types-core"
import { DashboardUserRole } from "store-config-interfaces"

// Constants defining default user roles
const NO_ROLES = "no-roles"
const NON_ADMIN_ROLE = "non-admin"

const HITL_USER_ROLES = ["super_admin", "hitl_admin", "hitl_operator"]

const isDashboardAdmin = (userRole: string): boolean => {
  return userRole === DashboardUserRole.Admin
}

const formatUserStoreAccess = (userStoreAccess: RawDashboardStoreAuthorization[]) => {
  const userStoreAccessFormatted: DashboardStoreAuthorization[] = userStoreAccess.map(
    (storeAuthorization: RawDashboardStoreAuthorization) => {
      const {
        id,
        email,
        zippin_store_id: storeId,
        user_role: userRole,
        created,
        updated,
        updated_by: updatedBy,
      } = storeAuthorization

      const formattedCreated = new Date(created)
      const formattedUpdated = new Date(updated)

      const formattedDashboardStoreAuthorization: DashboardStoreAuthorization = {
        id,
        email,
        storeId,
        userRole,
        created: formattedCreated,
        updated: formattedUpdated,
        updatedBy,
      }
      return formattedDashboardStoreAuthorization
    }
  )
  return userStoreAccessFormatted
}

const getCurrentStoreUserRole = (
  userStoreAccess: DashboardStoreAuthorization[],
  storeId: string
): string => {
  if (userStoreAccess != null && userStoreAccess.length > 0) {
    const currentStoreAccess = userStoreAccess.find(
      (storeAccess: DashboardStoreAuthorization) => storeAccess.storeId === storeId
    )
    // If user has access to the current store, return the role variable
    if (currentStoreAccess != null) return currentStoreAccess.userRole ?? NO_ROLES
  }
  return NO_ROLES
}

export {
  NO_ROLES,
  NON_ADMIN_ROLE,
  HITL_USER_ROLES,
  isDashboardAdmin,
  formatUserStoreAccess,
  getCurrentStoreUserRole,
}
