import React, { Component } from "react"

import TitledRoute from "./TitledRoute"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { getUserEmail, getUserRoles } from "../utils/api"
import { ZErrorComponent } from "./presentational/ZErrorComponent"

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

/**
 * A wrapped TitledRoute, which is only accessible,
 * if the user has been authenticated (logged in). If the user has
 * not logged in, the user will be redirected to login. Passes props
 * to the component rendered when the route is accessed.
 */
class PrivateRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuthorized: null,
    }
  }

  async componentDidMount() {
    this.checkUserAuthorization()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.roles !== this.props.roles || prevProps.path !== this.props.path) {
      this.setState({ isAuthorized: null })
      this.checkUserAuthorization()
    }
  }

  async checkUserAuthorization() {
    if (this.props.roles) {
      const userEmail = getUserEmail()
      const userRoles = await getUserRoles(userEmail)
      if (userRoles && userRoles.length > 0) {
        const hasRole = userRoles.some((role) => this.props.roles.includes(role.roleName))
        this.setState({ isAuthorized: hasRole })
      } else {
        this.setState({ isAuthorized: false })
      }
    } else {
      this.setState({ isAuthorized: true })
    }
  }

  render() {
    const { component, ...rest } = this.props
    const { isAuthorized } = this.state

    if (isAuthorized === null) {
      return <h4>Loading...</h4>
    }

    return isAuthorized ? (
      <TitledRoute
        {...rest}
        render={(routeProps) => {
          return renderMergedProps(this.props.component, routeProps, rest)
        }}
      />
    ) : (
      <ZErrorComponent errorMsg={`You are not authorized to view this page.`} />
    )
  }
}

/**
 * Auth0Provider will redirect back to this private route if the user hasn't
 * been authenticated yet.
 */
export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
})
