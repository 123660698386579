// Hooks dispatch action type
export const SET_SKU_PRICING_REQUIRED = "SET_SKU_PRICING_REQUIRED"
export const SET_SKU_ZERO_PRICING_ALLOWED = "SET_SKU_ZERO_PRICING_ALLOWED"
export const ON_SKU_SELECT_ADD = "ON_SKU_SELECT_ADD"
export const ON_SKU_SELECT_REMOVE = "ON_SKU_SELECT_REMOVE"
export const ON_SKU_SELECT_ALL = "ON_SKU_SELECT_ALL"
export const ON_SKU_SELECT_CLEAR = "ON_SKU_SELECT_CLEAR"
export const SKU_ERROR = "SKU_ERROR"
export const SKU_NAME_ERROR = "SKU_NAME_ERROR"
export const SKU_RESET_ERRORS = "SKU_RESET_ERRORS"
export const SUBMIT_ERROR = "SUBMIT_ERROR"
export const SKU_PRICE_ERROR = "PRICE_ERROR"
export const SKU_TAXRATE_ERROR = "TAXRATE_ERROR"
export const SKU_IMAGE_ERROR = "SKU_IMAGE_ERROR"
export const SKU_UNIT_WEIGHT_ERROR = "SKU_UNIT_WEIGHT_ERROR"
export const SKU_WEIGHT_ERROR = "WEIGHT_ERROR"
export const SKU_SET_EDIT = "SET_EDIT"
export const SET_SKU = "SET_SKU"
export const SET_SKU_WEIGHT = "SET_WEIGHT"
export const SET_SKU_PRICE = "SET_PRICE"
export const SET_SKU_TAXES = "SET_SKU_TAXES"
export const SET_SKU_CATEGORY = "SET_CATEGORY"
export const SET_SKU_NAME = "SET_NAME"
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_IMAGES = "SET_IMAGES"
export const RESET = "RESET"
export const SET_EMAIL = "SET_EMAIL"
export const SET_UPC_CODE = "SET_UPC_CODE"
export const SET_RECEIPT_NAME = "SET_RECEIPT_NAME"
export const SET_QUANTITY_ROUNDOFF = "SET_QUANTITY_ROUNDOFF"
export const FIXED = "fixed"
export const PERCENTAGE = "percentage"

// Tenant config keys
export const SKU_PRICING_REQUIRED = "pricing_required"
export const SKU_ZERO_PRICING_ALLOWED = "zero_pricing_allowed"

//SKU submit error messages
export const SKU_UNIT_WEIGHT_LESSTHAN_ONE_MESSAGE =
  "Sku attribute unit weight should not be less than 1."
export const SKU_IMAGE_REQUIRED_MESSAGE = "Sku attribute images is mandatory."
export const SKU_COMMON_ERROR_MESSAGE =
  "There was a problem saving the SKU. Please contact Zippin for details."
export const EMPTY_SKU_FILE_ERROR_MESSAGE =
  "Uploaded SKU file is empty. Please check and try again."
export const IMPORT_SKU_VALIDATION_SUCCESS_MESSAGE =
  "CSV file successfully verified. You can close this window and resume your work. We will notify you when the upload is complete."
export const IMPORT_SKU_RECORD_LENGTH_ERROR_MESSAGE =
  "Number of SKUs should not exceed 5000. Correct the file and import again."
// ramp pricing
export const SKU_RAMP_PRICING = "ramp_pricing"
export const SKU_MIN_RAMP_PRICING = "min_ramp_pricing"
export const SKU_MAX_RAMP_PRICING = "max_ramp_pricing"
export const SKU_RAMP_PRICING_PRICING_REQUIRED_MESSAGE =
  "Ramp pricing amount is mandatory / can't be negative."
export const STATUS_ACTIVE = "1"
export const STATUS_UP = "up"
export const STATUS_DOWN = "down"
export const SHOW_STRIPE_ADMIN_CONSOLE = "showStripeAdminConsole"

//import SKU template
export const IMPORT_SKU_TEMPLATE =
  "id,name,price,taxes,unitWeight,storeIds,categories,tags,thumbnails,upcCode,receiptName,quantityRoundoff,restore"

//import SKU Info
export const IMPORT_SKU_INFO = `id: The identifier of the SKU. Must be 1–16 characters. \n
name: The name of the SKU. \n
price: Optional. The unit price of the SKU. For e.g, 5 represents $5. Currency is set
at the store level. \n
taxes: Optional. A comma seperated tax code and value.
For e.g, The value represents a fixed amount or percentage based on the tax type. It supports 4 decimal places. If the decimals are
more than 4, it will be rounded off to 4 decimals. For instance, 8.75968 will
become 8.7597 and 8.75124 will become 8.7512. \n
unitWeight: The unit weight of the SKU in grams. \n
storeIds: A Comma seperated store ids. Defaults to all stores. \n
categories: A Comma seperated category codes. Defaults to default category. \n
tags: Optional. A Comma separated tags. \n
thumbnails: A Comma seperated thumbnail jpg urls for the SKU. Maximum size of a thumbnail is
1MB. \n
upcCode: Optional. Universal Product Code (UPC) for the SKU, for example, a UPC code like 01278907.\n
receiptName: Optional. The SKU's receipt name, for example, COKE\n
quantityRoundoff : optional. To round off the SKU quantity to an upper or lower number. The default value is 50\n. 
For example, if the quantity roundoff is 50, the SKU unit weight is 100, and the total weight of the selected SKU is 250,\n
then the SKU quantity is rounded to 3, and the total weight of the selected SKU is 240, then the SKU quantity is rounded to 2.\n
restore: Optional. Pass yes to reuse the same SKU that has been archived. \n`

//user management error messages
export const SELF_UPDATE_ERROR =
  "You can't add or remove access to your own account, please contact your manager."
export const REMOVE_ALL_ACCESS =
  "This action will remove all access and delete the user. Are you sure you want to delete the user?"
export const REMOVE_DASHBOARD_ACCESS =
  "This action will remove all dashboard access. Are you sure you want to continue?"
export const REMOVE_STORECREW_ACCESS =
  "This action will remove all store crew access. Are you sure you want to continue?"
//Shelf inventory constants
export const PENDING_SHELF_EVENTS_MESSAGE =
  "Accurate item count may be delayed because of store activity."
export const INCLUSIVE_PRICING_SCHEME = "tax-inclusive"
export const WEBHOOKS_TYPE = {
  CART_SUBMISSION_API: "cart_submission_api",
  NOTIFICATION_API: "notification_api",
  QR_CODE_VALIDATION_API: "qr_code_validation_api",
  PUSH_TRANSACTION_API: "push_transaction_api",
  LOYALTY_API: "loyalty_api",
}

export const RETAILER_VERIFY_VIEW_CONFIG = "retailer_verify_view_enabled"
export const RETAILER_VERIFY_VIEW_WINDOW_CONFIG = "retailer_verify_view_window_in_days"
export const DEFAULT_RETAILER_VERIFY_VIEW_WINDOW_IN_DAYS = 14
