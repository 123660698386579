import React from "react"
import SectionMessage from "@atlaskit/section-message"

export const ZErrorComponent = (props: {
  title?: string
  errorMsg: string
}): React.ReactElement => {
  return (
    <div color="error" style={{ alignItems: "center" }}>
      <SectionMessage appearance="error">
        <h3>
          {" "}
          {props.title ||
            "Oops there was an error! Please refresh; contact Zippin team if the error persists."}{" "}
        </h3>
        {props.errorMsg}
      </SectionMessage>
    </div>
  )
}
